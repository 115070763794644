@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
main,
#__next {
  width: 100%;
  height: 100%;
}
